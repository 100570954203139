<template lang="pug">
.siderBarContainer
  .userInfo(@click="handleJumpProfile")
    img.avatar(:src="userInfo.avatar")
    .name {{ userInfo.nickName }}
  el-menu(:router="true" active-text-color="var(--color-primary)" background-color="#545c64" text-color="#fff")
    template(v-for="(i,n) in menuList" )
      el-sub-menu(v-if="i.children" :index="`subMenu-${n}`")
        template(#title)
          div {{ i.name }}
        el-menu-item(v-for="j in i.children" :index="j.path")
          div {{ j.name }}
      el-menu-item(v-else :index="i.path")
        div {{ i.name }}

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'
import { userInfo } from '@/provider/account'
const { router } = inject('global')

const menuList = ref([
  { name: '首页', path: '/' },
  {
    name: '小程序管理',
    children: [
      { name: '小程序配置', path: '/config/app' },
      { name: '首页配置', path: '/config/home' }
    ]
  },
  {
    name: '文章管理',
    children: [
      { name: '新建文章', path: '/article/edit/new' },
      { name: '文章列表', path: '/article' }
    ]
  },
  {
    name: '职位管理',
    children: [
      { name: '新建职位', path: '/job/edit/new' },
      { name: '职位列表', path: '/job' },
      { name: '职位分类管理', path: '/jobCategory' }
    ]
  },
  {
    name: '公司管理',
    children: [
      { name: '新建公司', path: '/company/edit/new' },
      { name: '公司列表', path: '/company' }
    ]
  },
  {
    name: '评测管理',
    children: [{ name: '评测列表', path: '/evaluation' }]
  },
  {
    name: '系统配置',
    children: [
      { name: '城市管理', path: '/city' },
      { name: '文章分类管理', path: '/articleCategory' }
    ]
  },
  {
    name: '用户管理',
    children: [{ name: '用户账号管理', path: '/user' }]
  },
  {
    name: '系统管理',
    children: [{ name: '后台账号管理', path: '/account' }]
  }
])

function handleJumpProfile() {
  router.push('/account/profile')
}
</script>

<style lang="less" scoped>
.siderBarContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 200px;
  .userInfo {
    background: #545c64;
    color: white;
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid var(--color-border);
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      background: white;
    }
  }
  .el-menu {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
