<template lang="pug">
.mainContainer
  com-siderBar#siderBar(@change="onMenuChange")
  #innerView
    router-view

</template>

<script setup>
import { inject, computed } from 'vue'
import comSiderBar from '@/component/layout/siderBar.vue'

const { router } = inject('global')

function onMenuChange(val) {
  router.push(val.path)
}
</script>

<style lang="less" scoped>
.mainContainer {
  display: flex;
  height: 100vh;
  #siderBar {
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    border-right: 1px solid transparent;
  }
  #innerView {
    background: var(--color-background);
    flex-grow: 1;
    overflow: auto;
    position: relative;
  }
}
</style>
